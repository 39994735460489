<template>
  <div class="layout">
    
    <!-- <div v-if="(traceList.length === 0 || TimeOut)" class="empty ">
      <img src="@/assets/empty.png" />
      <div>本站当前油品溯源信息未知</div>
    </div> -->
    <div>
      <div
        class="rate-con"
        :style="{background: rateColorList[creditData.recordLevel].bg}"
      >
        <div
          class="time"
          :style="{background: rateColorList[creditData.recordLevel].timeBg}"
          >评分时间 {{ creditData.recordTime || '-----------'}} </div>       
        <div class="score-info">
          <div class="score-info-icon" @click="showScore = true" v-if="false">
            <van-image :src="require('../../assets/tips-white.png')" style="vertical-align: middle;" />
            得分指标
          </div>
          <van-image :src="require(`../../assets/${creditData.recordLevel}.png`)" />
          <div class="name" :style="{background: rateColorList[creditData.recordLevel].textBg}">{{ siteName }}</div>
        </div>
        <div class="rate-con-alarm flex">
          <template v-for="(item,key) in scoreList">
            <div 
              :key="key"
              :style="{background: `linear-gradient(345deg,#FFFFFF 65%, ${rateColorList[creditData.recordLevel].alarmBg} )`}"
              v-if="item.page"
            >
              <div class="alarm-label">{{ item.label }}</div>
              <div><Text :text="scoreIndex[key] || 0" color="#4E5968" fontWeight="bold" :fontSize="24" /></div>
            </div>
          </template>
        </div>
      </div>

      <div class="trace-con">
        <div class="trace-con-title flex">
          <van-image fit="contain" :src="require('../../assets/text.png')" />
          <div>
            {{ tankerName }}
          </div>
        </div>
        <!-- {{ traceList.length === 0  }}={{ TimeOut }} -->
        <div class="trace-con-oil">
          <div class="ndata" v-if="(traceList.length === 0 || TimeOut)">
            <van-image class="image" fit="contain" :src="require('../../assets/nodata-oil.png')" />
            <Text text="暂无油品追溯信息" color="#86909B" :fontSize="11" />
          </div>
          <div v-else>
            <div class="flex trace-con-oil-title" >
              <div
                :class="oilTypeActive === i ? 'active' : ''"
                v-for="(item, i) in traceList"
                :key="i"
                @click="handleOilType(item, i)">{{ item.oilName }}</div>
            </div>
            <div class="trace-con-oil-all">
              <div class="trace-con-oil-item" v-for="(item, key) in oilTraceList" :key="key">
                <div class="trace-con-item-title">
                  <van-image class="image" fit="contain" :src="item.icon" />
                  {{ item.label }}
                </div>
                <div class="trace-con-item-con">
                  <div class="flex item-cell" v-for="(obj, index) in item.children" :key="index">
                    <template v-if="obj.scopedSlots">
                      <div class="flex" 
                        style="width: 100%;justify-content: space-between;"
                        @click="handleImageStatus(true)">
                        <Text text="单据证明" color="#4E5968" :fontSize="13" />
                        <van-icon name="arrow" />
                      </div>
                    </template>
                    <template v-else>
                      <Text :text="obj.label" color="#4E5968" :fontSize="13" />
                      <Text :text="oilTypeTraceInfo[index]" color="#333333" :fontSize="13" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 得分指标 -->
    <CustomPupop key="scorePupop" :show="showScore" @close="handlePupopStatus" title="得分指标">
      <div class="popup-score">
        <div class="popup-score-item" v-for="(item, key) in scoreList" :key="key">
          <div class="flex">
            <Text :text="scoreIndex[key] || 0" :fontSize="36" margin="0 3px 0 0" color="#333333" /> /
            <Text :text="item.score" :fontSize="12" margin="0 0 0 3px" color="#4E5968" />
          </div>
          <div><Text :text="item.label" :fontSize="18" color="#4E5968" /></div>
        </div>
        <div>
          <div class="flex">
            <div style="padding: 3px 6px 0 0;"><van-image width="14" :src="require('../../assets/tips-gray.png')" /></div>
            <div style="text-align: left;">
              <Text text="加油站综合信用评分说明：" :lineHeight="20" :fontSize="12"  />
              <Text text="该综合信用评分主要基于油站公开数据开发，定位是油站综合社会信用评分，适用于对企业的社会信用进行评价，仅供参考。" color="#86909B" :lineHeight="20" :fontSize="12"  />
            </div>
          </div>
        </div>
      </div>
    </CustomPupop>
    <!-- 单据证明 -->
    <CustomPupop key="upopscoreP" :show="showBottom" height="90%" @close="handleImageStatus" title="单据证明">
      <div class="trace-popup" v-for="(img, index) of oilTypeTraceInfo.imgList" :key="index">
        <div class="trace-popup-title flex">
          <van-image class="image" fit="contain" :src="PIC_TITLES[index].icon" />
          <Text :text="PIC_TITLES[index].label" margin="0 0 0 6px" :fontSize="14" />
        </div>
        <Image
          :src="img"
          :item="oilTypeTraceInfo.tankerCode"
          height="8rem"
          fit="contain"
          :type="index"
          :border='true'
        >
          <template #index>{{ PIC_TITLES[index].label }}</template>
        </Image>
      </div>
    </CustomPupop>
    <!-- 弹框-信用评级 -->
    <van-popup :show="showdialog" round bind:close="onClose">
      <div :class="'popup-dialog A'">
        <!-- :style="{background: `${rateColorList[creditData.recordLevel].dialogBg}`}" -->
        <div class="dialog-img"
        ><van-image class="image" fit="contain" :src="require(`../../assets/bg-mbl-${creditData.recordLevel}.png`)" /></div>
        <div class="dialog-text">
          <div><Text text="综合信用评分分为5个等级" :fontSize="14" margin="0 0 10px 0 " /></div>
          <div><Text text="该油站目前处于" :fontSize="20" /></div>
          <div>
            <Text :text="creditText[creditData.recordLevel]" :color="rateColorList[creditData.recordLevel].dialogTextColor" :fontSize="20" display="inline" />
            <Text text="状态" :fontSize="20" display="inline" />
          </div>
          <div
          class="dialog-text-btn"
          :style="{'background': `${rateColorList[creditData.recordLevel].dialogTextColor}`}"
          @click="handleHiddenDialog">我知道了</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { Notify } from 'vant';
import CustomPupop from '@/components/Popup/index.vue'
import Image from '../../components/Image.vue';
import { getOilTrace, creditRating, getMachineCheck } from '../../api';
import {sortArrayDate} from '@/utils/utils.js'
// icon-popup1.png
import IconPopup1 from '@/assets/icon-popup1.png'
import IconPopup2 from '@/assets/icon-popup2.png'
import IconPopup3 from '@/assets/icon-popup3.png'
import dayjs from 'dayjs';
import {rateColorList, scoreList, oilTraceList} from './cloumns'
import { handleDecrypt } from '@/utils/utils';
import { useRoute } from 'vue-router';
const route = useRoute();

const traceList = ref([]);
const indicatorWidth = ref(0);
const creditData = ref({})
const creditText = {
  A: '信用极好',
  B: '信用优良',
  C: '信用较好',
  D: '信用一般',
  E: '信用较差',
  undefined: '暂无评价',
}
const showdialog = ref(false)
// 获取所需的参数值
const siteCode =
  localStorage.getItem('siteCode').replace(/^\s+|\s+$/g, '');
const tankerCode =
  localStorage.getItem('tankerCode').replace(/^\s+|\s+$/g, '');
let siteName = localStorage.getItem('siteName')
let tankerName = localStorage.getItem('tankerName')
// const dialogFlag = localStorage.getItem('dialogFlag')
// 页面图片标题模块名称映射
const PIC_TITLES = {
  outOrderImg: {
    label: '出库单',
    icon: IconPopup1
  },
  carImg: {
    label: '运输车辆',
    icon: IconPopup2
  },
  certImg: {
    label: '油品合格证',
    icon: IconPopup3
  }
};
// 判断最后一次是否超时
const TimeOut = computed(() => {
  if (!traceList.value[0]) return false;
  console.log(traceList.value[0].completeTime, 'traceList.value[0].completeTime')
  const time = dayjs(traceList.value[0].completeTime);
  const currentTime = dayjs();
  const difference = currentTime.diff(time);
  return difference > 90 * 24 * 60 * 60 * 1000;
});

const getIndicatorWidth = (num) => {
  const regex = /^\+?[1-9][0-9]*$/;
  if (!regex.test(num)) {
    // throw new Error('num 必须为正整数！')
    return 0;
  }
  const padding = 16 * 2; // 左右内边距
  return (document.body.clientWidth - padding) / num;
};

// 得分指标
const scoreIndex = ref({})
const handleCreditRating = async () => {
  const params = {creditCode: siteCode}
  console.log(siteCode)
  try{
    await creditRating(params).then(res=> {
      const {status,data,msg} = res;
      console.log(res)
      console.log(localStorage.getItem('dialogFlag'), 2222222222)
      if (localStorage.getItem('dialogFlag') == '1') {
        showdialog.value = true;
      }
      if (status === 0) {
        if (data && data.details) {
          creditData.value = data
          data.details.forEach(item => {
            const sore = `${item.detailsSort}`.slice(0, 2);
            if (!scoreIndex.value[sore]) {
              scoreIndex.value[sore] = Number(item.detailsRiskCount)
              // scoreIndex.value[sore] = Number(item.detailsValue)
            } else {
              scoreIndex.value[sore] += Number(item.detailsRiskCount)
              // scoreIndex.value[sore] += Number(item.detailsValue)
            }
          })
        } else {
          Notify({
            type: 'warning',
            message: msg
          });
        }
      } else {
        const type_msg = msg === '油站信息不存在请联系管理员' ? '暂无评价' : msg;
        Notify({
          type: 'danger',
          message: type_msg
        });
      }
    })
  } catch {
    console.log('catch ssssssssss')
    Notify({
      type: 'danger',
      message: '系统错误！'
    });
  }
}
const transformResData = (data) => {
  let newTraceData = [];
  if (!(data instanceof Array)) {
    throw new Error("The type of 'data' is not array");
  }
  data.forEach((item) => {
    const { carImg, certImg, outOrderImg, ...otherData } = item;
    const obj = {
      ...otherData,
      imgList: {
        certImg,
        carImg,
        outOrderImg,
      },
    };
    newTraceData.push(obj);
  });

  indicatorWidth.value = getIndicatorWidth(newTraceData.length);

  return newTraceData;
};

const handleMachineCheck = (params) => {
  getMachineCheck(params).then(res => {
    if (res.data){
      localStorage.setItem('siteName', res.data.siteName);
      siteName = res.data.siteName
      localStorage.setItem('tankerName', res.data.tankerName);
      tankerName = res.data.tankerName
    }
  })
}
const { p, site_code } = route.query;
onMounted(async () => {
  await handleCreditRating();
  const params =  handleDecrypt({p, site_code})
  console.log(siteName, 'dddddddd')
  if (!siteName) {
    await handleMachineCheck({
      siteCode: params.site_code,
      tankerCode: params.tanker_code,
    });
  }
  const res = await getOilTrace({
    siteCode,
    tankerCode,
  });
  if (res.status === 0) {
    if (res.data && res.data.length > 0) {
      traceList.value = sortArrayDate(
        transformResData(res.data),
        'completeTime'
      );
      oilTypeTraceInfo.value = traceList.value[0]
    }
  }
});
const showScore = ref(false)
const handlePupopStatus = (falg) => {
  showScore.value = falg
}
const showBottom = ref(false)
const handleImageStatus = (falg) => {
  showBottom.value = falg
}
const handleHiddenDialog = () => {
  localStorage.setItem('dialogFlag', '0');
  showdialog.value = false
}

const oilTypeActive = ref(0);
const oilTypeTraceInfo = ref({});
const handleOilType = (item, i)  => {
  oilTypeActive.value = i;
  oilTypeTraceInfo.value = item;
}
</script>

<style lang="less" scoped>
.layout {
  padding-bottom: 16px;
  box-sizing: border-box;
  background: #F3F4F7;
}
.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  color: #333333;
  text-align: center;
  font-family: 'PingFang SC';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  img {
    width: 200px;
    height: 200px;
  }
}

.rate-con {
  padding: 16px;
  font-size: 12px;
  text-align: center;
  color: #4E5968;
  .time {
    line-height: 24px;
    color: #fff;
    width: 80%;
    margin: 0 auto;
  }

  .score-info {
    position: relative;
    color: #fff;
    padding: 16px 0 48px;
    &-icon{
      position: absolute;
      top: 32px
    }

    .name {
    border-radius: 40px;
    padding: 9px 0;
    font-size: 16px;
    width: 84%;
    margin: -32px auto;
    position: relative;
    }
  }
  &-alarm {
    border-radius: 8px;
    div +div{
      margin-left: 8px;
    }

    & > div {
      width: 25%;
      border-radius: 8px;
      padding: 16px 0;
    }
    .alarm-label {
      margin-bottom: 11px;
    }
  }
}
.flex {
  display: flex;
}
.popup-score {
  text-align: center;
  padding: 0 17px 24px;
  box-sizing: border-box;
  &-item {
    width: 50%;
    padding: 0 7px 18px ;
    box-sizing: border-box;
    display: inline-block;
    margin-top: 16px;
    &::before {
      content: '';
      display: inline-block;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, #B7D0FF 0%, #F4F9FF 95%);
      margin-bottom: 18px;
    }
    .flex{ 
      justify-content: center;
      align-items: baseline;
    }
    &:nth-child(1) {
      background: url(../../assets/score-enterprise.png) no-repeat center center;
    }
    &:not(:nth-child(1)) {
      background: url(../../assets/score-risk.png) no-repeat center center;
    }
  }
}

.trace-con {
  background: #1664FB;
  border-radius: 8px;
  padding: 16px 8px 8px;
    margin-bottom: 45px;
  .image {
    vertical-align: middle;
  }
  &-title {
    justify-content: space-between;
    padding: 0 8px;

    div:nth-child(2) {
      background: #387BFF;
      padding: 6px 11.5px;
      border-radius: 4px;
      color: #fff;
      font-size: 14px;

      &::before {
        content: '';
        display: inline-block;
        border-radius: 50%;
        background: #fff;
        width: 6px;
        height: 6px;
        margin-right: 2px;
      }
    }
  }
  &-oil {
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 16px;
    &-title {
      background: #CEDEFF;
      color: #333333;
      border-radius: 8px 8px 0 0 ;
      padding: 10px 16px;
      & > div{
        padding: 0 15px;
        position: relative;
      }
      .active {
        color: #1664FF;
        &::after{
          content: '';
          display: block;
          width: 24px;
          height: 2px;
          background: #1664FF;
          position: absolute;
          left: 50%;
          bottom: -10px;
          margin-left: -12px;
        }
      }
    }

    &-all {
      padding: 0 16px 20px;
      .trace-con-item-title {
        margin: 19px 0 10px;
      }
      .trace-con-item-con {
        background: #F4F9FF;
        border-radius: 8px;
        padding: 8px 16px;
        margin-left: 30px;
        .trace-con-oil-item {
          margin-top: 14px;
          color: #333;
        }
        .item-cell {
          justify-content: space-between;
        }
      }
    }
  }
}

.trace-popup {
  padding: 0 16px;

  .trace-popup-title {
    margin: 10px 0 3px
  }
}
.popup-dialog {
  width: 280px;
  height: 368px;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  .dialog-text {
    padding-top: 12px;
    margin-top: -35px;;
    position: absolute;
    bottom: 16px;
    width: 100%;
    .dialog-text-btn {
      line-height: 32px;
      border-radius: 16px;
      color: #fff;
      display: inline-block;
      padding: 0 24px;
      margin-top: 16px;
      font-size: 14px;
    }
  }
}
.ndata {
  background: #fff;
  border-radius: 8px;
  text-align: center;
  padding: 38px;
}
</style>