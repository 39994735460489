import axios from "axios";
// import { Toast } from 'vant'
// import router from "../router";

const service = axios.create({
    baseUrl: "/api",
});

service.interceptors.request.use(
    (config) => {
        config.headers = {
            // 'Content-Type': 'application/x-www-form-urlencoded'
            // 'Content-type': 'multipart/form-data'
        };
        config.withCredentials = true;
        config.headers['BRAND-MARK'] = localStorage.getItem('loginType');
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    (response) => {
        const res = response.data;

        // if (res.status === 1) {
        //     // Toast.error(res.msg)
        //     return Promise.reject(new Error(res.msg || "Error"));
        // } else {
        // }
        return res;
    },
    (error) => {
        // // Toast.error(error);
        // if (error.toString().includes("status code 500")) {
        //     router.replace({
        //         path: "/500",
        //         query: { error: error.response.statusText },
        //     });
        // }
        return Promise.reject(error);
    }
);

export default service;
