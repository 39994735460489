<template>
  <van-popup
      v-model:show="visible"
      position="bottom"
      teleport="body"
      round
      closeable
      :style="{ height: props.height }"
      @close="close"
    >
      <div class="popup-title">
        {{ title }}
        <!-- <van-icon name="cross" class="popup-title-icon" @click="close" /> -->
      </div>
    <slot></slot>
  </van-popup>
</template>

<script setup>
import {  ref,defineProps, defineEmits, watchEffect } from "vue";
const props = defineProps({
 title: {
  type: String,
  default: ''
 },
 show: {
  type: Boolean,
  default: false
 },
 height: {
  type: String,
  default: '80%'
 }
})
const visible =ref(false)
watchEffect (() => {
  console.log(props.show, props.title, ';;;;;;;')
  visible.value = props.show
})
const emits = defineEmits(['close'])
const close = () => {
  emits('close', false);
  visible.value = false;
}
</script>

<style lang="less" scoped>
.popup-title {
  font-size: 18px;
    text-align: center;
    padding: 16px;
    color: #333;
    position: relative;
    &-icon {
      position: absolute;
      right: 16px;
    }
}
</style>
