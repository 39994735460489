// export const alarmList = {
//   punish: { label: '行政处罚' },
//   operating: { label: '经营异常' },
//   measurement: { label: '计量质量异常' },
//   tax: { label: '税务风险' },
// }

export const rateColorList = {
  undefined: {
    timeBg: `linear-gradient(to bottom, 'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0.3)', 'rgba(255, 255, 255, 0)')`,
    bg: `linear-gradient(to bottom, #2058F3, #41A8FD, #E9F0FF)`,
    textBg: '#1664FF',
    alarmBg: '#51AEFE',
		// dialogBg: 'linear-gradient(0deg, #7CEFD0 , #63E9C6 )',
    dialogTextColor: '#1664FF'
  },
  A: {
    timeBg: `linear-gradient(to bottom, #63E9C6, 'rgba(15, 209, 141, 0.69)', #63E9C6)`,
    bg: `linear-gradient(to bottom, #63E9C5, #AAF5E1, #F3F4F7)`,
    textBg: '#00BB6A',
    alarmBg: '#51FEC1',
		dialogBg: 'linear-gradient(0deg, #7CEFD0 , #63E9C6 )',
    dialogTextColor: '#38DB94'
  },
  B: {
    timeBg: `linear-gradient(to bottom, #71F09B, 'rgba(27, 191, 57, 0.69)', #71F09B)`,
    bg: `linear-gradient(to bottom, #71EF9B, #C0F0B9, #F4F5F8)`,
    textBg: '#1DD259',
    alarmBg: '#51FE65',
		dialogBg: 'linear-gradient(0deg, #97F2AF , #53DB80 )',
    dialogTextColor: '#67E691'
  },
  C: {
    timeBg: `linear-gradient(to bottom, #FED95D, #DBBA00, #FED95D)`,
    bg: `linear-gradient(to bottom, #FFD446, #FFF6D2, #F3F4F7)`,
    textBg: '#FCC100',
    alarmBg: '#FFDC65',
		dialogBg: 'linear-gradient(0deg, #FFF1A6 , #FED95D)',
    dialogTextColor: '#FAD868'
  },
  D: {
    timeBg: `linear-gradient(to bottom, 'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0.3)', 'rgba(255, 255, 255, 0)')`,
    bg: `linear-gradient(to bottom, #FFA759, #FFEACD, #F3F4F7)`,
    textBg: '#F28100',
    alarmBg: '#FFC362',
		dialogBg: 'linear-gradient(0deg, #FFC778 , #FFA75A)',
    dialogTextColor: '#FFB752'
  },
  E: {
    timeBg: `linear-gradient(to bottom, 'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0.3)', 'rgba(255, 255, 255, 0)')`,
    bg: `linear-gradient(to bottom, #FF5C5F, #EEA9AA, #F3F4F7)`,
    textBg: '#FF0004',
    alarmBg: '#FF0004',
		dialogBg: 'linear-gradient(0deg, #FF8E90 , #FF0004)',
    dialogTextColor: '#FE474B'
  },
}

export const scoreList = {
  10: { label: '企业经营状况', score: 7 },
  20: { label: '税务风险', score: 9, page: true },
  30: { label: '经营风险', score: 18, page: true },
  40: { label: '市场监管风险', score: 15, page: true },
  50: { label: '商务风险', score: 9 },
  60: { label: '应急风险', score: 10 },
  70 : { label: '信用风险', score: 12 },
  80: { label: '行政处罚风险', score: 20, page: true },
}

import Tab1 from '@/assets/tab-1.png';
import Tab2 from '@/assets/tab-2.png';
import Tab3 from '@/assets/tab-3.png';
import Tab4 from '@/assets/tab-4.png';
export const oilTraceList = 
  [{
    label: '油品来源',
    icon: Tab1,
    children: {
      refineryName: { label: '来源炼厂' },
      density: { label: '密度（kg/m³）' },
      takingSum: { label: '进油量（吨）' }
    }
  }, {
    label: '运输追溯',
    icon: Tab2,
    children: {
      oilDepot: { label: '发货油库:' },
      licenseNum: { label: '运输车辆:' }
    }
  }, {
    label: '卸油站点',
    icon: Tab3,
    children: {
      siteName: { label: '加  油 站:' },
      tankNum: { label: '油罐编号:' },
      tankerName: { label: '加  油 机:' },
      gunName: { label: '加  油 枪:' },
      completeTime: { label: '卸油时间:' }
    }
  }, {
    label: '溯源证明',
    icon: Tab4,
    children: {
      Proof: {scopedSlots: true}
    }
  }]
