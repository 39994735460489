<template>
  <van-nav-bar
    title="追溯查询"
    left-arrow
    @click-left="onClickLeft"
    fixed
    placeholder
  />
  <van-form @submit="onSubmit">
    <van-cell-group style="margin: 16px 16px 0">
      <van-field
        v-model="state.stationName"
        name="stationName"
        label="加油站名称"
        readonly
        placeholder="加油站"
      />
      <van-field
        v-model="state.tankerName"
        readonly
        name="tankerName"
        label="加油机编号"
        placeholder="点击选择加油机编号"
        @click="showMachinePicker != true"
      />
      <van-popup
        v-model:show="showMachinePicker"
        position="bottom"
        teleport="body"
      >
        <van-picker
          title="加油机编号"
          :columns="machineColumns"
          @confirm="onMachineConfirm"
          @cancel="showMachinePicker = false"
        />
      </van-popup>
      <van-field
        v-model="state.oilName"
        readonly
        :clickable="oilList.length > 1"
        name="oilName"
        label="油品"
        placeholder="点击选择油品"
        @click="onPickerOilName"
      />
      <van-popup
        v-model:show="showOilTypePicker"
        position="bottom"
        teleport="body"
      >
        <van-picker
          title="油品"
          :columns="oilTypeColumns"
          @confirm="onOilTypeConfirm"
          @cancel="showOilTypePicker = false"
        />
      </van-popup>

      <van-field
        v-model="state.time"
        readonly
        clickable
        name="time"
        label="加油时间"
        placeholder="点击选择加油时间"
        @click="showDatePicker = true"
      />
      <van-popup
        v-model:show="showDatePicker"
        position="bottom"
        teleport="body"
      >
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onDateConfirm"
        />
      </van-popup>
    </van-cell-group>

    <div style="margin: 16px;">
      <van-button block type="primary" native-type="submit">查询</van-button>
    </div>
  </van-form>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import { getOilTrace } from '../api';
import { Notify } from 'vant';

const router = useRouter();
// const route = useRoute();

const siteName = localStorage.getItem('siteName') && localStorage.getItem('siteName').replace(/^\s+|\s+$/g, '');
const siteCode =
  localStorage.getItem('siteCode').replace(/^\s+|\s+$/g, '');
const tankerCode =
  localStorage.getItem('tankerCode').replace(/^\s+|\s+$/g, '');
const tankerName = localStorage.getItem('tankerName') && localStorage.getItem('tankerName').replace(/^\s+|\s+$/g, '');
let oilList = null;
if (
  localStorage
    .getItem('oilList')
    .replace(/^\s+|\s+$/g, '')
    .includes(',')
) {
  oilList = localStorage
    .getItem('oilList')
    .replace(/^\s+|\s+$/g, '')
    .split(',');
} else {
  oilList = [localStorage.getItem('oilList').replace(/^\s+|\s+$/g, '')];
}
// 加油机列表
const machineColumns = [
  { text: '1号加油机', id: 1 },
  { text: '2号加油机', id: 2 },
  { text: '3号加油机', id: 3 },
  { text: '4号加油机', id: 4 },
  { text: '5号加油机', id: 5 },
  { text: '6号加油机', id: 6 },
];

let oilTypeColumns = oilList;
if (oilTypeColumns[0] === '') {
  oilTypeColumns = ['92#', '95#', '98#', '0#', '-10#']
}

const currentDate = ref(new Date());
const maxDate = new Date();
const minDate = new Date(2020, 0, 1);

const onClickLeft = () => {
  router.back();
};

const showMachinePicker = ref(false);
const showOilTypePicker = ref(false);
const showDatePicker = ref(false);

const state = reactive({
  stationName: siteName,
  siteCode,
  tankerCode,
  tankerName,
  oilName: oilList[0],
  time: '',
});

const onMachineConfirm = (value) => {
  state.tankerCode = value.text;
  // state.tankerId = value.id
  showMachinePicker.value = false;
};

const onOilTypeConfirm = (value) => {
  state.oilName = value;
  showOilTypePicker.value = false;
};

const onPickerOilName = () => {
  // if (oilList.length > 1) {
  // }
  showOilTypePicker.value = true;
};

const formatDate = (date) => dayjs(date).format('YYYY-MM-DD');
const onDateConfirm = (value) => {
  state.time = formatDate(value);
  showDatePicker.value = false;
};

const onSubmit = async (values) => {
  const params = {
    siteCode,
    tankerCode,
    time: values.time,
    oilName: values.oilName,
  };
  console.log(values);
  const res = await getOilTrace(params);
  if (res && res.status === 0) {
    const data = res.data;
    console.log(data);
    if (data && data.length > 0) {
      router.push({
        path: '/result',
        query: {
          data: encodeURIComponent(JSON.stringify(params)),
          // data: encodeURIComponent(JSON.stringify(data))
        },
      });
    } else {
      Notify({ type: 'error', message: '无法查到相关溯源信息！' });
    }
    return;
  } else {
    Notify({ type: 'error', message: '数据请求失败，请稍后再试！' });
    return;
  }
};
</script>

<style></style>
