<template>
  <div :class="!checkInfo.controlState ? 'layout online' : 'layout offline'">
    <van-config-provider :theme-vars="cellThemeVars">
      <div class="siteBasis">
        <div class="flex">
          <div>
            <div><Text :text="siteName" :fontSize="18" color="#FFFFFF" /> </div>
            <div class="tanker-name">
              <van-image v-if="!checkInfo.controlState" class="image" fit="contain" :src="require('../../assets/icon-online.png')" />
              <van-image v-else class="image" fit="contain" :src="require('../../assets/icon-offline.png')" />
              {{ tankerName }}
            </div>
          </div>
          <div>
            <van-image v-if="!checkInfo.controlState" class="image" fit="contain" :src="require('../../assets/tanker-online.png')" />
            <van-image v-else class="image" fit="contain" :src="require('../../assets/tanker-offline.png')" />
          </div>
        </div>
        <div class="offline-warn" v-if="checkInfo.controlState">
          <van-image class="image" fit="contain" :src="require('../../assets/offline-warn.png')" />
          该加油机监管设备处于离线状态，请谨慎加油
        </div>
      </div>
      <div class="checkResult">
        <div class="frame">
          <div class="borderB">
            <van-image class="image" fit="contain" :src="require('../../assets/icon-checkinfo.png')" />
            <Text text="油机检定信息" :fontSize="14" margin="0 0 0 6px" display="inline" />
          </div>
          <template v-if="checkInfo && checkInfo.lastNote">
            <div v-for="(item, key) in checkInfoCloumn" :key="key">
              <Text :text="`${item.label}：`" color="#4E5968" :fontSize="13"  display="inline" />
              <Text :text="checkInfo[key]" :fontSize="13"  display="inline" />
            </div>
          </template>
          <template v-else>
            <div class="nodata">
              <van-image class="image" fit="contain" :src="require('../../assets/nodata-check.png')" />
              <Text text="暂无油机检定信息" color="#86909B" :fontSize="11" />
            </div>
          </template>
        </div>
        <div class="frame-b">
          <div class="borderB">
            <van-image class="image" fit="contain" :src="require('../../assets/icon-oilcheck.png')" />
            <Text text="油品抽检" :fontSize="14" margin="0 0 0 6px" display="inline" />
          </div>
          <template v-if="checkInfo && checkInfo.checkList.length > 0">
            <div class="gun" v-for="(item, key) in checkInfo.checkList" :key="key">
              <div v-for="(obj, index) in checkOilInfoCloumn" :key="index">
                <Text :text="`${obj.label}：`" color="#4E5968" :fontSize="13"  display="inline" />
                <Text v-if="!obj.scopedSlots" :text="item[index]" :fontSize="13"  display="inline" />
                <template v-else>
                  <van-image  v-if="item.status === 0" class="image" fit="contain" :src="require('../../assets/result1.png')" />
                  <van-image v-else class="image" fit="contain" :src="require('../../assets/result2.png')" />
                </template>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="nodata">
              <van-image class="image" fit="contain" :src="require('../../assets/nodata-oil.png')" />
              <Text text="暂无油品抽检信息" color="#86909B" :fontSize="11" />
            </div>
          </template>
        </div>
      </div>
    </van-config-provider>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getMachineCheck } from '../../api';
// 去除两端空格 .replace(/^\s+|\s+$/g, '')
const siteCode = localStorage.getItem('siteCode').replace(/^\s+|\s+$/g, '');
const tankerCode = localStorage.getItem('tankerCode').replace(/^\s+|\s+$/g, '');
const siteName = ref(localStorage.getItem('siteName'))
const tankerName = ref(localStorage.getItem('tankerName'))
const checkInfoCloumn = {
  utensilNum: {label: '器具编号'},
  expireTime: {label: '有效期至'},
  measureExamOrg: {label: '检定机构'},
  lastNote: {label: '检定结果'},
}
const checkOilInfoCloumn = {
  gunNum: {label: '加油枪编号'},
  time: {label: '计量检定时间'},
  expireTime: {label: '计量检定结果',scopedSlots:true},
}
const cellThemeVars = {
  cellTextColor: 'var(--van-gray-6)',
  cellValueColor: 'var(--van-black)',
  cellGroupTitleColor: 'var(--van-gray-7)',
  cellGroupInsetTitlePadding: '16px 8px 8px 24px',
  cellGroupTitleFontSize: 'var(--van-font-size-lg)',
};

const checkInfo = ref({
  name: '',
  num: '',
  expireTime: '',
  measureExamOrg: '',
  complaint: '',
  spotTestDate: '',
  checkList: [
    // {
    //   gunNum: 1,
    //   checkList: '合格',
    //   spotTestDate: '2023/11/01'
    // }, {
    //   gunNum: 3,
    //   checkList: '不合格'
    // }
  ],
});

onMounted(async () => {
  // checkInfo.value = {"expireTime":"2024-12-31 00:00:00","tankerName":"1号加油机","utensilNum":" ","lastNote":"合格","checkList":[{"gunNum":1,"id":12,"time":"2024-08-14","status":1},{"gunNum":2,"id":13,"time":"2024-08-14","status":0},{"gunNum":3,"id":14,"time":"2024-08-14","status":0},{"gunNum":4,"id":15,"time":"2024-08-14","status":0}],"siteName":"临沂经济技术开发区石化有限公司1","id":531,"factoryNum":"57460020C003","measureExamOrg":"沂河新区计量测试检定所","controlState":false,"tankerId":2374}
  const res = await getMachineCheck({
    siteCode,
    tankerCode,
  });
  if (res.status === 0 && res.data) {
    checkInfo.value = res.data;
    siteName.value =res.data.siteName;
    tankerName.value =res.data.tankerName;
  }
});
</script>

<style>
.cell-text {
  flex: 0 1 1;
}
.cell-value {
  flex: 2;
  font-size: 16px;
  width: 100%;
}
</style>

<style lang="less" scoped>
.layout {
  box-sizing: border-box;
  min-height: calc(100vh - 90px);
  padding: 0;
}
.online {
  background: linear-gradient(180deg, #2159F4 0.01%, #5CB4FE 19.59%, #EAF1FF 30.62%);;
}
.offline {
  background: linear-gradient(180deg, #F52528 0.01%, #FF7171 19.59%, #EAF1FF 30.62%);;
}
.siteBasis {
  padding: 16px 16px 30px;
  position: relative;
  .tanker-name {
    background: #fff;
    display: inline-block;
    padding:  0 16px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 10px;
  }
  .offline-warn {
    background: #FEF8EE;
    padding: 10px;
    border-radius: 4px;
    color: #FF9631;
    font-size: 11px;
    position: absolute;
    width: calc(100% - 40px);
    box-sizing: border-box;
    left: 20px;
    bottom: -20px;
  }
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.image {
  vertical-align: middle;
}
.borderB {
  border-bottom: 1px solid #F2F2F2;
}
.frame {
  background: #fff;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 12px 16px;
}

.nodata {
    text-align: center;
    padding-top: 6px;
}
.frame .borderB ,
.frame-b .borderB {
    padding-bottom: 8px;
    margin-bottom: 10px;
  }
.frame-b {
  background: #fff;
  border-radius: 8px;
  padding: 12px 0 12px;
  .borderB {
    padding-left:  16px;
  }
  .gun {
    padding: 0  16px 16px;;
  }
  .gun +.gun{
    padding-top:  6px;;
    border-top: 2px solid #F3F4F7;
  }
}
.checkResult {
  background: #F3F4F7;
  border-radius: 12px 12px 0 0 ;
  padding: 30px 12px 64px;
}
.header {
  background: #fff;
  margin-bottom: 10px;
  padding: 12px 16px;
  box-sizing: border-box;
}
.title {
  font-size: 20px;
  letter-spacing: 1px;
}
.note {
  font-size: 14px;
  color: #666;
}

.check-item {
  margin-top: 16px;
}
.check-item:not(:last-child) {
  margin-bottom: 16px;
}
.check-item__title {
  line-height: 30px;
  font-size: 16px;
  letter-spacing: 1px;
  color: #333;
}

.spot-test .date {
  color: #1989fa;
}
</style>
<style>
.cell-group {
  box-shadow: 0px 12px 18px 0px rgba(214, 214, 214, 0.2);
  border-radius: 0;
}

</style>
