<template>
  <span
    :style="{
      color: props.color,
      display: props.display,
      margin: props.margin,
      width: props.width,
      fontWeight: props.fontWeight,
      'text-align': props.textAlign,
      'font-size': `${props.fontSize}px`,
      'white-space': props.whiteSpace,
      'line-height': `${props.lineHeight}px`
    }"
    @click="handleClick"
  >
    {{ text }}
    <slot></slot>
  </span>
</template>

<script setup>
import {  defineProps, defineEmits } from "vue";
const props = defineProps({
  text: {
    type: [String, Number],
    default: ''
  },
  class: {
    type: String,
    default: ''
  },
  display: {
    type: String,
    default: 'block'
  },
  color: {
    type: String,
    default: '#333'
  },
  margin: {
    type: String,
    default: '0 0 10 0'
  },
  fontSize: {
    type: Number,
    default: 14
  },
  width: {
    type: String,
    default: ''
  },
  textAlign: {
    type: String,
    default: ''
  },
  whiteSpace: {
    type: String,
    default: ''
  },
  lineHeight: {
    type: Number,
    default: 32
  },
  fontWeight: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['click'])

const handleClick = () => {
  emit('click')
}
</script>

<style>
.text {
  width: 100%;
  line-height: 32px;
}
</style>
