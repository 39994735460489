<template>
  <div class="layout" style="padding-top: 16px">
    <van-config-provider :theme-vars="cellThemeVars">
      <van-cell-group inset class="cell-group">
        <van-cell
          title="加油站名称"
          value-class="cell-value"
          :value="checkInfo.siteName"
        />
        <van-cell
          title="加油机编号"
          value-class="cell-value"
          :value="checkInfo.tankerName"
        />
        <van-cell
          title="设备状态"
          value-class="cell-value"
        > <template #value v-if="checkInfo.controlState != undefined">
          <!-- -{{ checkInfo.controlState }}= -->
              <van-button v-if="!checkInfo.controlState" icon="passed" type="primary" size="mini">在线</van-button>
              <van-button v-else icon="close" type="danger" size="mini">离线</van-button>
            </template>
        </van-cell>
        <div class="offline" v-if="checkInfo.controlState"><van-icon name="info-o" color="#FE9B29" />该加油机监管设备处于离线状态，请谨慎加油</div>
      </van-cell-group>
      <van-cell-group
        v-if="checkInfo && checkInfo.lastNote"
        title="油机检定信息"
        inset
        class="cell-group"
      >
        <van-cell
          title="器具编号"
          value-class="cell-value"
          :value="checkInfo.utensilNum"
        />
        <van-cell
          title="有效期至"
          value-class="cell-value"
          :value="checkInfo.expireTime"
        />
        <van-cell
          title="检定机构"
          title-class="cell-text"
          value-class="cell-value"
          :value="checkInfo.measureExamOrg"
        />
        <van-cell
          title="检定结果"
          title-class="cell-text"
          value-class="cell-value"
          :value="checkInfo.lastNote"
        />
        <!-- <van-cell
          title="举报电话"
          value-class="cell-value"
          :value="checkInfo.complaint"
        /> -->
      </van-cell-group>
      <van-cell-group
        v-if="checkInfo && checkInfo.checkList.length > 0"
        inset
        class="cell-group"
        title="油品抽检"
        style="margin-bottom: 16px;"
      >
        <template v-for="(item, index) in checkInfo.checkList" :key="index">
          <van-cell
            title="加油枪编号"
            value-class="cell-value"
            :value="`${item.gunNum}号加油枪`"
          />
          <van-cell title="计量检定结果" value-class="cell-value">
            <template #value>
              <!-- <van-tag
                :type="item.checkList == '合格' ? 'success' : 'danger'"
                size="medium"
              >{{ item.checkList }}</van-tag> -->
              <van-button v-if="item.status === 0" icon="passed" type="primary" size="mini">合格</van-button>
              <van-button v-else icon="close" type="danger" size="mini">不合格</van-button>
              <!-- <span
                :class="'info-success'"
                :style="{
                  color: item.status == 0 ? '#1D62ED' : '#FF3329',
                }"
                >{{ item.status === 0 ? '合格' : '不合格' }}</span
              > -->
            </template>
          </van-cell>
          <van-cell
            title="计量检定时间"
            value-class="cell-value"
            :value="item.time"
          />
          <van-divider
            v-if="index == checkInfo.checkList.length - 1 && index !== 0"
            style="border-color: transparent"
          ></van-divider>
        </template>
      </van-cell-group>
    </van-config-provider>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getMachineCheck } from '../api';
// 去除两端空格 .replace(/^\s+|\s+$/g, '')
const siteCode = localStorage.getItem('siteCode').replace(/^\s+|\s+$/g, '');
// const siteName = localStorage.getItem('siteName')
const tankerCode = localStorage.getItem('tankerCode').replace(/^\s+|\s+$/g, '');

const cellThemeVars = {
  cellTextColor: 'var(--van-gray-6)',
  cellValueColor: 'var(--van-black)',
  cellGroupTitleColor: 'var(--van-gray-7)',
  cellGroupInsetTitlePadding: '16px 8px 8px 24px',
  cellGroupTitleFontSize: 'var(--van-font-size-lg)',
};
// const siteInfo = ref({
//   siteName,
//   tankerCode
// });
const checkInfo = ref({
  name: '',
  num: '',
  expireTime: '',
  measureExamOrg: '',
  complaint: '',
  spotTestDate: '',
  checkList: [
    // {
    //   gunNum: 1,
    //   checkList: '合格',
    //   spotTestDate: '2023/11/01'
    // }, {
    //   gunNum: 3,
    //   checkList: '不合格'
    // }
  ],
});

onMounted(async () => {
  const res = await getMachineCheck({
    siteCode,
    tankerCode,
  });
  if (res.status === 0 && res.data) {
    checkInfo.value = res.data;
    res.data.siteName && localStorage.setItem('siteName', res.data.siteName);
    res.data.tankerName &&
      localStorage.setItem('tankerName', res.data.tankerName);
  }
});
</script>

<style>
.cell-text {
  flex: 0 1 1;
}
.cell-value {
  flex: 2;
  font-size: 16px;
  width: 100%;
}
</style>

<style scoped>
.layout {
  box-sizing: border-box;
  min-height: calc(100vh - 90px);
  /* background: #fff; */
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header {
  background: #fff;
  margin-bottom: 10px;
  padding: 12px 16px;
  box-sizing: border-box;
}
.title {
  font-size: 20px;
  letter-spacing: 1px;
}
.note {
  font-size: 14px;
  color: #666;
}

.check-item {
  margin-top: 16px;
}
.check-item:not(:last-child) {
  margin-bottom: 16px;
}
.check-item__title {
  line-height: 30px;
  font-size: 16px;
  letter-spacing: 1px;
  color: #333;
}

.spot-test .date {
  color: #1989fa;
}
</style>
<style>
.cell-group {
  box-shadow: 0px 12px 18px 0px rgba(214, 214, 214, 0.2);
  border-radius: 0;
}

.offline {
  background: #FEF8EE;
  padding: 10px;
  border-radius: 4px;
  color: #FE9B29;
}
</style>
