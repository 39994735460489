<template>
  <van-nav-bar
    title="成品油溯源码"
    fixed
    placeholder
    v-if="slideVerifyTrue && isWechatCore"
    />
  <div v-if="!isWechatCore" style="text-align: center;line-height: 80px;">
    无数据，请使用微信扫描
  </div>

  <!-- 滑块验证 -->
  <div class="slider-con" v-if="isWechatCore && !slideVerifyTrue">
    <div class="slider">
    <div class="cover">
      <van-image width="70%" :src="require('../assets/sliderverify.png')" />
      <div>为保证您的信息安全，请进行验证</div>
    </div>
    <slide-verify
      style="display: block;"
      @onSuccess="(val) => (slideVerifyTrue = val)"
      handlerIcon="arrow"
      successIcon="passed"
    ></slide-verify>
  </div>
  </div>
  
  <!-- 临沂市 -->
  <template v-if="areaCodeFalg">
    
    <div v-if="slideVerifyTrue && isWechatCore">
      <Trace v-if="active === 0" />
      <CheckDetail v-else-if="active === 1" />
      <Comments v-else-if="active === 2" />
    </div>
    
    <van-tabbar v-model="active" @change="onChangeTab" v-if="slideVerifyTrue && isWechatCore">
      <van-tabbar-item v-for="(item, index) in tabList" :key="index">
        <template #icon>
          <van-image
            :src="active === index ? item.iconS: item.icon"
            mode="aspectFit"
            style="width: 20px;"
          />
        </template>
        {{ item.label }}
      </van-tabbar-item>
    </van-tabbar>
  </template>
  <!-- 其他县市 -->
  <template v-else>
    <van-tabs
    v-if="slideVerifyTrue && isWechatCore"
      v-model:active="active"
      sticky
      animated
      swipeable
      :offset-top="isWechatCore ? 0 : 40"
      @change="onChangeTab"
    >
      <van-tab title="油品追溯" style="margin-top: 0;">
        <TracePage />
      </van-tab>
      <van-tab title="油机检定" style="margin-top: 0;">
        <CheckDetailPage />
      </van-tab>
      <van-tab title="投诉意见" style="margin-top: 0;">
        <CommentsPage />
      </van-tab>
    </van-tabs>
    
    <flat-button @select="onSelect" v-if="slideVerifyTrue && isWechatCore" />
  </template>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { isWechat } from '@/utils/utils';
// Pages
import Trace from './Trace/index.vue';
import CheckDetail from './CheckDetail/index.vue';
import Comments from './Comments.vue';

// Pages
import TracePage from './Trace.vue';
import CheckDetailPage from './CheckDetail.vue';
import CommentsPage from './Comments.vue';
import SlideVerify from '@/components/SliderVerify.vue'; //引入
// Components
import FlatButton from '../components/FlatButton.vue';
import { decrypt } from '@/utils/utils';
import { getMachineCheck } from '../api';
// 图标
import  Oiltype from '../assets/icon-oiltype.png';
import  OiltypeS from '../assets/icon-oiltype-s.png';
import  Tanker from '../assets/icon-tanker.png';
import  TankerS from '../assets/icon-tanker-s.png';
import  Opinion from '../assets/icon-opinion.png';
import  OpinionS from '../assets/icon-opinion-s.png';

const slideVerifyTrue = ref(false);
const router = useRouter();
const route = useRoute();

const isWechatCore = ref(isWechat());
const active = ref(route.query.tabIndex * 1 || 0);
const tabList = [
  {
    label: '油品追溯',
    icon: Oiltype,
    iconS: OiltypeS
  },
  {
    label: '油机检定',
    icon: Tanker,
    iconS: TankerS,
  },
  {
    label: '意见投诉',
    icon: Opinion,
    iconS: OpinionS,
  },
]

const handleMachineCheck = (params) => {
  getMachineCheck(params).then(res => {
    // console.log(res);
    if (res.data){
      localStorage.setItem('siteName', res.data.siteName);
      localStorage.setItem('tankerName', res.data.tankerName);
    }
  })
}

const { p, site_code } = route.query;
let areaCodeFalg = ref(false);
const storeData = (params) => {
  localStorage.setItem('dialogFlag', '1');
  localStorage.setItem('siteCode', params.site_code);
  localStorage.setItem('tankerCode', params.tanker_code);
  localStorage.setItem('loginType', params.login_type);
  localStorage.setItem('encryptP', p);
  localStorage.setItem('encryptSiteCode', site_code);
  // localStorage.setItem('loginType', params.login_type);
  const code = params.login_type;
  // 枣庄、德州、临沂
  if (code.indexOf('3704') > -1 ||code.indexOf('3714') > -1 ||code.indexOf('3713') > -1 || code == '') {
    areaCodeFalg =true;
  }
  handleMachineCheck({
    siteCode: params.site_code,
    tankerCode: params.tanker_code,
  })
};

const routerPush = ref({});
const handleDecrypt = () => {
  localStorage.setItem('siteName', '');
  localStorage.setItem('tankerName', '');
  const _site_code = decrypt(site_code);
  const _p = decrypt(p);
  const arr = _p.split('&');
  routerPush.value = {
    site_code: _site_code,
    tanker_code: arr[0].split('=')[1],
    // site_id: arr[1].split('=')[1],
    login_type: arr[1].split('=')[1],
  };
  // 存储信息
  storeData(routerPush.value);
};
handleDecrypt();

const onChangeTab = (e) => {
  active.value = e
  router.push({
    path: '/',
    query: {
      p,
      site_code,
      tabIndex: e,
      t: Math.floor(Math.random() * 9999999999),
    },
  });
};

const onSelect = ({ index }) => {
  if (index === 0) {
    router.push({
      path: '/search',
      query: {
        ...route.query,
      },
    });
  } else {
    if (index === 1) {
      router.push({
        path: '/',
        query: {
          ...route.query,
          tabIndex: 1,
        },
      });
    } else if (index === 2) {
      router.push({
        path: '/',
        query: {
          ...route.query,
          tabIndex: 2,
        },
      });
    }
    active.value = index;
  }
}
</script>
<style lang="less">
.slider-con {
  width: 100%;
    height: 100vh;
    background: #fff;
    position: absolute;
    z-index: 10;
}
.van-tabs__line {
  background: linear-gradient(to right, #ff3329, #ff7d43);
  box-shadow: 0px 4px 9px 1px rgba(255, 103, 96, 0.7);
  width: 50px;
  height: 4px;
  border-radius: 0;
}

.cover {
  text-align: center;
  color: #618dee;
  margin-bottom: 50px;
  line-height: 60px;
}
.slider {
  width: fit-content;
  // background-color: white;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
</style>
