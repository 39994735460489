import request from "../utils/request";

export function creditRating(data) {
    return request({
        url: "/api/oilStation/creditRating",
        method: "post",
        data,
    });
}

export function getOilTrace(data) {
    return request({
        url: "/api/oilStation/traceCode",
        method: "post",
        data,
    });
}

export function getMachineCheck(data) {
    return request({
        // url: "/api/checkRecord",
        url: "/admin/api/checkRecord",
        method: 'post',
        data,
    });
}

export function getComments(data) {
    return request({
        url: "/admin/api/opinion",
        method: "post",
        data,
    });
}
