import { ref } from 'vue';
import { JSEncrypt } from 'jsencrypt';
export function isWechat() {
    var ua = navigator.userAgent.toLowerCase();
    if(ua.match(/AlipayClient/i) == 'alipayclient'){
        // 支付宝
        return true;
    } else if (ua.match(/MicroMessenger/i) == "micromessenger") {
        // 微信
        return true;
    } else {
        return false;
    }
}

export const toFixed = (n) => {
	if (isNaN(n)) {
		throw new Error('the variable is not a number')
	}

	return Number(n).toFixed(2)
}

const privateKey = ref(`-----BEGIN RSA PRIVATE KEY-----
MIIBOAIBAAJAftv6MRY3J4WYDnGTo72z5jWUPDQBfaERk404BrQQXX5o2vgWNjcs
1MYmrAE+DSxmJ7aV/MBO2qnArCyRcDmrQQIDAQABAkAC1fYEzONUvdbFU+KiAWaZ
Gc+DfQIfhc83xitfcvz+qgFQCz2NIXIoWYA8MdjQaVJn9MTIMy7Mg8IdCwzIvkPB
AiEA8zo8Z2+KP2Ax/yu7Pax3izwOCNbKP/Djrbly1dfavhkCIQCFhWBcwZvAlPdO
ha0ZUi3PVr7237hfsqHK0EW7oZ+raQIgXE+o5zTtCjqPHyJQ0Qpo4H4tcWtbnULm
lmOA0uo2idkCIAsv4KIE6rrZAFuI4bb0ZrgviXJ/zh/ehKXMi3ssEIe5AiAmi5am
T4csPHKXVrMs9IU2lCwSaWgXhJOVnzZ9kok8xQ==
-----END RSA PRIVATE KEY-----`);
export const decrypt = (code) => {
    const v = decodeURIComponent(code);
    var decrypt = new JSEncrypt();
    decrypt.setPrivateKey(privateKey.value);
    var decrypted = decrypt.decrypt(v);
    return decrypted;
}


// 数组对象根据时间排序
export const sortArrayDate = (arr, key) => {
    arr.sort((a, b) => {
      const dateA = new Date(a[key]);
      const dateB = new Date(b[key]);
      return dateB - dateA;
    });
    return arr;
  };

  
export const handleDecrypt = (params) => {
    const _site_code = decrypt(params.site_code);
    const _p = decrypt(params.p);
    const arr = _p.split('&');
    const routerPush = {
      site_code: _site_code,
      tanker_code: arr[0].split('=')[1],
      // site_id: arr[1].split('=')[1],
      login_type: arr[1].split('=')[1],
    };
    // 存储信息
    return routerPush
  };